.activity-log-wrapper .ant-input-group>.ant-input:first-child {
  padding-right: 43px;
}

.activity-log-wrapper .ant-input-group>.ant-input:first-child {
  border-radius: 5px !important;
  float: right;
  width: 100%;
}

.task-assigned-wrapper button,
.activity-log-wrapper button,
.tenants-detail-profile button,
.project-details button,
.tenants-detail-profile button,
.user-management button,
.project button,
.btn-action-div button {
  padding: 0px 5px 0px 5px;
}


.rentRoll button>.active-action,
.task-assigned-wrapper button>.active-action,
.activity-log-wrapper button>.active-action,
.tenants-detail-profile button>.active-action,
.user-detail-wrapper button>.active-action,
.project-details button>.active-action,
.user-management button>.active-action,
.project button>.active-action {
  padding: 5px 30px;
  text-align: center;
  border-radius: 5px !important;
}


.task-assigned-wrapper button:hover>.active-action,
.activity-log-wrapper button:hover>.active-action,
.tenants-detail-profile button:hover>.active-action,
.user-detail-wrapper button:hover>.active-action,
button:focus>.active-action,
.project-details button:hover>.active-action,
.project button:hover>.active-action,
.user-management button:hover>.active-action,
.bg-active {
  background-color: #f2f3f5;
  padding: 5px 30px;
  text-align: center;
  border-radius: 5px !important;
}

.task-assigned-wrapper button:hover,
.activity-log-wrapper button:hover,
.user-detail-wrapper button:hover,
.tenants-detail-profile button:hover,
.project button:hover,
.project-details button:hover,
.user-management button:hover,
.btn-action-div button:hover {
  /* background-color: transparent !important; */
}