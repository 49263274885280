/*** corporate-dashboard-wrapper **/

.corporate-dashboard-wrapper .ant-card-body {
  padding: 0px 10px;
}

.recent-task .ant-card-body {
  padding: 0px 10px;
}

.top-tanents-wrapper .ant-card-body,
.asset-available-for-rent .ant-card-body,
.expires-assets .ant-card-body {
  padding: 0px 10px 10px !important;
}

.corporate-dashboard-wrapper .ant-table-thead .ant-table-cell {
  padding: 0;
  font-size: 12px !important;
  background-color: #fff;
  color: black;
}

.corporate-dashboard-wrapper .ant-table-tbody .ant-table-cell {
  padding: 0;
  font-size: 12px !important;
  background-color: #fff;
  color: black;
  font-weight: 400;
}

.corporate-dashboard-wrapper .btn-primary {
  color: white;
  background: #00215b;
  padding: 3px 7px;
  border-radius: 4px;
  font-size: 12px;
  height: auto;
}


.corporate-dashboard-wrapper .btn-primary:hover {
  color: white !important;
  background: #00215b !important;
}

.corporate-dashboard-wrapper .btn-primary:active {
  color: #00215b;
  border-color: #00215b;
}


.taskchat-wrapper .chat-avatar {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.taskchat-inner-wrapper .ant-card-body {
  padding: 10px 0 !important;
}

.corporate-dashboard-wrapper .avatar {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 5px;
}

.corporate-dashboard-wrapper .expires-assets .ant-table-tbody .ant-table-cell {
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: #F2F3F5 1px solid;
  /* border-radius: 0; */
}

.corporate-dashboard-wrapper .top-tanents-wrapper .ant-table-tbody .ant-table-cell {
  padding-bottom: 3px;
  padding-top: 3px;
  text-align: left !important;
  border-bottom: #F2F3F5 1px solid;
  border-radius: 0px;
}

.top-tanents-wrapper .ant-table-tbody .ant-table-cell:first-child {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.top-tanents-wrapper .ant-table-tbody .ant-table-cell:last-child {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.top-tanents-wrapper .antd-custom-card-table .ant-table table {
  border-spacing: 0 !important;
}

.corporate-dashboard-wrapper .top-tanents-wrapper .ant-table-tbody .ant-table-cell:last-child {
  text-align: right !important;
}

.top-tanents-wrapper .ant-table-tbody {
  padding: 0 10px 10px !important;
}

.corporate-dashboard-wrapper .avail-rent-wrapper {
  height: 100%;
  min-height: 181px;
  max-height: 181px;
  overflow: hidden;
  overflow-y: scroll;
}

.corporate-dashboard-wrapper .recent-task-scroll {
  max-height: 360px;
  min-height: 360px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

/*********** Corporate Dashboard *******/
/* .taskname-card {
  background-color: #F8F8F8;
  border: none;
  padding: 0;
} */

/* .taskname-card-active {
  background-color: #00215b;
  border: none;
  padding: 0;
  line-height: 1.15;
  font-size: 10px !important;
} */

/* .taskname-card-active p,
.taskname-card-active h5 {
  color: #fff !important;
  line-height: 1.15;
  font-size: 10px !important;
} */
.taskchat-chatcard-inner,
.taskchat-card-inner,
.taskname-card p {
  line-height: 1.15;
  font-size: 10px !important;
}

.taskchat-wrapper .bg-blue {
  background-color: #00215b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.taskchat-wrapper .ant-input-affix-wrapper {
  border-radius: 5px !important;
  padding: 5px !important;
}

.taskchat-wrapper .ant-input-group-addon {
  border-radius: 0;
  padding: 0;
  background-color: #fff;
}

.taskchat-wrapper .ant-input-suffix {
  margin-left: 4px;
  display: flex;
}

.taskchat-chatcard-inner,
.taskchat-card-inner,
.taskchat-card p {
  font-size: 10px !important;
}


/* .taskname-card-active .ant-card-body,
.taskchat-card-inner .ant-card-body,
.taskname-card .ant-card-body, */
.taskchat-card .ant-card-body {
  padding: 0px !important;
}

.taskchat-chatcard-inner .bg-dark-gray
.taskchat-card-inner .bg-dark-gray {
  background-color: #F8F8F8 !important;
}

/* .taskchat-card-inner {
  min-height: 214px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 214px;
} */

/* .recent-task .container {
  display: relative;
  width: 30px;
  height: 60px;
  margin-left: 0;
}

.circle {
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translate(40%, -50%);
  width: 12px;
  height: 12px;
  border: 2px solid #00215b;
  background-color: #fff;
  border-radius: 100%;
  z-index: 1;
}

.circle-fill {
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translate(40%, -50%);
  width: 12px;
  height: 12px;
  border: 2px solid #00215b;
  background-color: #00215b;
  border-radius: 100%;
  z-index: 1;
}

.line {
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 133%;
  background-color: #B2B5B8;
  z-index: 0;
}
   */

/*********** Responsive Sidebar ********/

@media (max-width: 992px) {
  .antd-custom-layout .ant-layout .custom-header {
    left: 0px;
    padding: 0px 25px 0px 25px !important;
  }

  .antd-custom-layout .ant-layout {
    margin-left: 0px;
  }
}

.corporate-dashboard-wrapper .triangle-down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px;
  border-color: #00215B transparent transparent #00215B;
  position: absolute;
  bottom: -1px;
  right: 0;
  border-radius: 5px 0px 0px 0px;
  transform: rotate(180deg);
}

.corporate-dashboard-wrapper .arrow-right {
  color: #fff;
  font-size: 14px;
  position: absolute;
  right: -1px;
  bottom: -1px;
}

.corporate-dashboard-wrapper .asset-carousel .ant-card-body {
  padding: 0 !important;
}

.corporate-dashboard-wrapper .asset-carousel p {
  font-size: 12px;
}

.corporate-dashboard-wrapper .asset-carousel-img {
  width: 100%;
  height: 125px;
  object-fit: cover;
  border-radius: 5px;
}

.corporate-dashboard-wrapper .small-counter {
  background-color: #FF7226;
  color: #fff;
  padding: 0 15px;
  border-radius: 4px 0px;
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.task-assigned-wrapper .assign-task-avatar{
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}
.recent-task .assign-task-avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.recent-task .pending-status {
  padding: 1px 15px;
  background-color: #FFB4581A;
  color: #FFB458;
  border-radius: 16px;
  font-size: 12px;
}

.asset-carousel.react-multi-carousel-list {
  position: unset !important;
}

.recent-task .complete-status {
  padding: 1px 15px;
  background-color: #5CDB5C1A;
  color: #5CDB5C;
  border-radius: 16px;
  font-size: 12px;
}

.corporate-dashboard-wrapper .drawer-close-btn {
  position: absolute;
  top: 4.7rem;
  right: 1rem;
  padding: 5px;
  background: rgba(89, 90, 92, 1);
  border-radius: 5rem;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  z-index: 1;
}

.recent-task .drawer-close-btn {
  position: absolute;
  top: 5rem;
  left: -0.3rem;
  padding: 5px;
  background: rgba(89, 90, 92, 1);
  border-radius: 5rem;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  z-index: 1;
}

.asset-expire-wrapper .ant-table-tbody .ant-table-cell {
  padding: 5px;
  background-color: #fff;
  font-weight: 400;
  color: black;
}

.asset-expire-wrapper .ant-table-thead .ant-table-cell {
  padding: 0;
  background-color: #fff;
  font-weight: 400;
  color: black;
}

/* .taskchat-wrapper .chat-avatar {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 50%;
} */

/* .corporate-dashboard-wrapper .recent-task-scroll {
  height: 380px;
  overflow: hidden;
  overflow-y: scroll;
} */

.taskchat-chatcard-inner p,
.taskchat-card-inner p,
.taskname-card p {
  line-height: 1.15;
  font-size: 10px !important;
}

.taskchat-wrapper .taskchat-card-inner {
  min-height: 201px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 201px;
}

.taskchat-wrapper .ant-input-affix-wrapper:hover {
  border-color: #00215b !important;
}

.taskchat-wrapper .ant-input-affix-wrapper:active {
  border-color: #00215b !important;
}

.taskchat-wrapper .ant-input-affix-wrapper-focused {
  border-color: #00215b !important;
  box-shadow: none !important;
}

/********** Tenants **********/
.total-tenants .antd-custom-card-table .ant-table-tbody .ant-table-cell {
  padding: 7px;
}

.tenants-search .searchbar-icon {
  left: 1.5em;
}

.tenants-detail-profile .tannet-detail-box {
  background-color: rgba(242, 243, 245, 1);
  padding-left: 10px;
}

.tenants-detail-profile .tannet-detail-col {
  padding-right: 0px !important;
}

.tenants-detail-profile .upload-btn {
  padding: 0px 40px 0px 40px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.tenants-detail-profile .upload-btn svg {
  margin-right: 5px;
}

.tenants-detail-profile .upload-btn:hover {
  border-color: #00215b !important;
}

/******** Task Assigned ********/
.ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .table-dropdown .cancel-menu {
  margin-left: 30px !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .table-dropdown:hover .cancel-menu {
  color: #00215b !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content .table-dropdown:hover .mark-complete {
  color: #00215b !important;
}