.bg-image {
  background-image: url("../images/login_bg_img.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.pages-component {
  height: 100vh;
}
.sign-in-card {
  padding: 10px 50px 10px 50px;
}
.input-transperant {
  padding: 0px 23px 10px 0px;
  border: none;
  outline: none;
  border-bottom: 1px solid #445366;
  font-size: 16px;
  border-radius: 0 !important;
}
.input-transperant:focus {
  box-shadow: none;
}

.input-transperant::placeholder {
  font-size: 16px;
  color: #b2b5b8;
  font-weight: 500;
}
.sign-in-right-heading {
  color: #ffffff;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
}
.sign-in-right-text {
  color: #ffffff;
  font-size: 31px;
  line-height: 40px;
  font-weight: 200;
  padding-top: 15px;
}
.form-field-link {
  color: #ff7226 !important;
  font-size: 15px;
  font-weight: 500;
}
.form-field-link:hover {
  color: #ff7226;
  font-size: 15px;
  font-weight: 500;
}
.input-icons {
  color: #b2b5b8;
  right: 70px;
}
.loginComponent .input-mail-icon {
  top: 122px;
}
.loginComponent .input-key-icon {
  top: 188px;
}
.input-fname-icon {
  top: 97px;
}
.input-lname-icon {
  top: 160px;
}

.sign-in-left-title {
  color: #00215b !important;
  font-weight: 600 !important;
  font-size: 30px !important;
}
.sign-in-btn {
  background-color: #00215b !important;
  font-size: 17px;
  border-color: #00215b;
}
.sign-in-btn.disabled {
  background-color: #999a9d !important;
  border-color: #999a9d !important;
}
.sign-in-btn:hover {
  border-color: #00215b;
  background-color: #00215b;
}

/* Login Page */
.loginComponent .forget-pass {
  font-size: 15px;
  padding-top: 9px;
}

/* .sign-in-card .ant-card-body .ant-form .ant-form-item-has-error {
  margin-bottom: 0px;
} */

.ant-select-selector:focus {
  box-shadow: none !important;
  border-color: #00215b !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #00215b;
  box-shadow: none;
}
/* .ant-form-item-explain-error {
  margin-bottom: 24px;
} */
.new-project-search-bar {
  float: right;
  gap: 8px;
  position: absolute;
  right: 80px;
  top: 85px;
}
.search-form-filter-row .ant-select-selection-placeholder {
  font-weight: normal;
  color: #b2b5b8;
}
.search-form-filter-row .ant-input::placeholder {
  font-weight: normal;
  color: #b2b5b8;
}
.ant-input {
  border-radius: 5px;
}
.ant-input:focus {
  border-color: #00215b;
}
.new-table-icon {
  position: absolute;
  right: 48px;
  top: 95px;
}

.ant-modal-close-x {
  display: none;
}
.ant-select-selector {
  border-radius: 5px !important;
}

.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #00215b !important;
  border: 1px solid #00215b !important;
  overflow: hidden;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00215b;
  border: 1px solid #00215b;
  overflow: hidden;
}
.ant-checkbox-checked::after {
  border-color: #00215b;
}
.ant-menu-overflow {
  justify-content: center;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #00215b;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: #00215b;
  border-bottom: 2px solid #00215b;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: #00215b;
  border-bottom: 2px solid #00215b;
}
.site-layout-background {
  border-top: 1px solid #f2f4f7;
  padding: 0px !important;
}

.DropdownItems {
  color: #9b9ea1;
}
.DropdownItems:active {
  color: #00215b;
}
.signing-input-fields .ant-input:hover {
  border-color: #00215b !important;
}
.signing-input-fields .ant-input-focused,
.signing-input-fields .ant-input:focus {
  border-bottom-color: #00215b !important;
}
.ant-input:focus {
  box-shadow: none;
}
.ant-input:hover {
  border-color: #00215b;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #00215b;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #00215b !important;
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  border-color: #00215b !important;
}
/* .signupComponent .ant-form-item-explain-error{
  margin-bottom: 20px !important;
} */
