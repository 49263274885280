.rounded-circle-spacing {
  width: 55px;
  height: 55px;
}
.arrow-up-icon-bg {
  background-color: #5cdb5c1a;
}
.arrow-up-icon {
  color: #6edf6e;
  margin: 12px;
}
.arrow-down-icon-bg {
  background-color: #ff36361a;
}
.arrow-down-icon {
  color: #ff5454;
  margin: 12px;
}
.plus-icon-bg {
  background-color: #ffb4581a;
}
.plus-icon {
  color: #ffb458;
  margin: 12px;
}
.trending-icon-bg {
  background-color: #6c87ff1a;
}
.trending-icon {
  color: #6c87ff;
  margin: 12px;
}
.users-card-scroll {
  height: 480px;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.asset-details-stepper .users-card-scroll {
  height: 525px !important;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.asset-details-stepper .ant-steps {
  padding-left: 2px;
}
.asset-details-stepper .ant-input-number,
.asset-details-stepper .ant-picker {
  border-radius: 5px;
}
.asset-details-stepper .ant-input-number-focused,
.asset-details-stepper .ant-input-number:hover,
.asset-details-stepper .ant-input-number:active,
.asset-details-stepper .ant-input-number:focus,
.asset-details-stepper .ant-picker:hover,
.asset-details-stepper .ant-picker:active,
.asset-details-stepper .ant-picker:focus,
.asset-details-stepper .ant-picker-focused {
  border-color: #00215b;
  box-shadow: none;
}
.asset-details-stepper .ant-select-selector,
.asset-details-stepper .ant-input-number-input,
.asset-details-stepper .ant-picker {
  font-weight: 500;
}

.users-top-icon {
  background-color: #f2f3f5;
  padding: 10px;
  border-radius: 5px;
}
.users-name-abbr {
  border-radius: 5px;
  background-color: #f2f3f5;
  padding: 10px 16px;
}
.active-badge {
  background-color: #effbef;
  color: #6edf6e;
  border-radius: 30px;
}
.most-least-assets {
  background-color: rgba(108, 135, 255, 0.1);
  color: #6c87ff;
  border-radius: 30px;
}
.inactive-badge {
  background-color: rgba(255, 54, 54, 0.1);
  color: #ff3636;
  border-radius: 30px;
}
.subscriber-badge {
  background-color: rgba(255, 180, 88, 0.1);
  color: #ffb458;
  border-radius: 30px;
}
