/* Reset Password Page*/
.resetComponent .sign-in-text {
  width: 320px;
  padding-bottom: 34px;
  color: #797774;
}

.resetComponent .input-key-icon {
  width: 20px;
  height: 25px;
  color: #b2b5b8;
  top: 162px;
}
.resetComponent .input-confirm-key-icon {
  width: 20px;
  height: 25px;
  color: #b2b5b8;
  top: 227px;
}
.resetComponent .arrow-icon {
  width: 20px;
  height: 20px;
  color: #595a5c;
  position: relative;
  top: 6px;
  right: 5px;
}

.resetComponent .ant-input-suffix {
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.resetComponent .input-transperant {
  padding: 0px 0px 5px 0px;
}
