/* .admin-parameter-wrapper .action-btn {
    background-color: #B2B5B8;
} */

.all-activity-wrapper .ant-divider-vertical {
  height: 2.5em;
  border-left: 2px solid #e6ebf1;
}

.user-detail-wrapper .bg-light-grey,
.custom-drawer .bg-light-grey,
.all-activity-wrapper .bg-light-grey {
  background-color: #f2f3f5 !important;
}

.all-activity-wrapper .text-light-grey {
  color: #a1a1a1 !important;
}

.activity-log-wrapper .bg-light-grey {
  background-color: #f2f3f5 !important;
  border-radius: 5px;
}

/* .activity-log-wrapper .bg-light-grey-eye {
  background-color: #f2f3f5 !important;
  border-radius: 5px;
}

.activity-log-wrapper .bg-light-grey-eye:hover {
  background-color: #00215B !important;
}

.activity-log-wrapper .bg-light-grey-eye:hover svg {
  color: #fff;
} */
.activity-log-wrapper .search-icon {
  color: rgba(178, 181, 184, 1);
}

.activity-log-wrapper .activity-count {
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
}

.admin-dashboard-wrapper .bg-blue {
  background: #00215b;
}

.admin-dashboard-wrapper .tanent-row .fill-eye {
  display: none;
}

.admin-dashboard-wrapper .tanent-row:hover .fill-eye {
  display: block;
}

.admin-dashboard-wrapper .tanent-row:hover {
  background-color: #f2f3f5;
  color: #00215b;
}

.admin-dashboard-wrapper .custom-scroll {
  height: 725px;
  overflow: hidden;
  overflow-y: scroll;
  list-style-type: none;
}

.admin-dashboard-wrapper .custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(217, 217, 217, 1);
  border-radius: 10px;
}

.admin-dashboard-wrapper .fixed-height {
  height: 725px;
}

.admin-dashboard-wrapper a {
  color: #595a5c;
  text-decoration: underline;
}

.admin-dashboard-wrapper a:hover {
  color: #00215b;
}

.admin-dashboard-wrapper .text-light-gray {
  color: #9c9c9c;
  font-weight: 300;
  font-size: 13px;
}

.admin-dashboard-wrapper .chart {
  position: relative;
}

.admin-dashboard-wrapper .select {
  position: absolute;
  top: 0;
  right: 0;
}

.admin-dashboard-wrapper .color-green {
  color: #158582;
}

.admin-dashboard-wrapper .color-red {
  color: #db5461;
}

.admin-dashboard-wrapper .color-blue {
  color: #2978a0;
}

.user-detail-wrapper .projects-count {
  /* box-shadow: 1px 1px 1px 1px #00215b14 inset; */
  box-shadow: inset 0 0 4px rgba(0, 33, 91, 0.3);
  /* border: #9C9C9C 1px solid; */
  width: 5rem;
  height: 5rem;
}

.user-detail-wrapper .project-drawer-details {
  height: calc(100vh - 495px);
  overflow-y: auto;
  list-style-type: none;
}

.user-detail-wrapper .custom-height {
  height: calc(100vh - 557px);
  overflow-y: auto;
  list-style-type: none;
}

.user-detail-wrapper .ant-collapse-header-text {
  color: #00215b;
  font-size: 16px;
  font-weight: 500;
}

.user-detail-wrapper .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
  padding: 12px 20px 12px 16px;
}

.user-detail-wrapper .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
  top: 40%;
  font-size: 18px;
}

.asset-dashboard-wrapper .text-subtitle {
  color: #969696;
}

.asset-dashboard-wrapper .bg-light-blue {
  background-color: #f2f4f7 !important;
}

.asset-dashboard-wrapper .bg-gray-dark {
  background-color: #0e141e1a !important;
}

.asset-dashboard-wrapper .ant-card-body {
  padding: 0;
}

.asset-dashboard-wrapper .text-orange {
  color: #ff7226;
}

/* .asset-dashboard-wrapper .sensivity-analysis-wrapper .ant-table-tbody>tr>td, */
.asset-dashboard-wrapper .validation-results-wrapper .ant-table-tbody>tr>td {
  border: none;
}

.asset-dashboard-wrapper .sensivity-analysis-wrapper .ant-table-tbody>tr> :last-child,
.asset-dashboard-wrapper .sensivity-analysis-wrapper .ant-table-thead>tr> :last-child,
.asset-dashboard-wrapper .sensivity-analysis-wrapper .ant-table-thead>tr> :nth-child(1),
.asset-dashboard-wrapper .sensivity-analysis-wrapper .ant-table-tbody>tr> :nth-child(1) {
  border-radius: 0;
  border: none !important;
}

.asset-dashboard-wrapper .sensivity-analysis-wrapper .ant-table-content .ant-table-cell {
  border-radius: 0;
  border-bottom: none !important;
  border-right: #000000 1px solid;
}

.asset-dashboard-wrapper .sensivity-analysis-wrapper .ant-table-thead .ant-table-cell {
  color: #00215b;
  font-weight: 500;
}

.asset-dashboard-wrapper .validation-results-wrapper .ant-table-thead .ant-table-cell {
  border-bottom: none !important;
  color: #00215b;
  font-weight: 500;
}

.asset-dashboard-wrapper .tanent-analysis-wrapper .ant-table-thead .ant-table-cell,
.asset-dashboard-wrapper .area-analysis-wrapper .ant-table-thead .ant-table-cell {
  color: #fff !important;
  border: #fff 1px solid;
}

.asset-dashboard-wrapper .tanent-analysis-wrapper .antd-custom-card-table .ant-table table,
.asset-dashboard-wrapper .area-analysis-wrapper .antd-custom-card-table .ant-table table {
  border-spacing: 0;
}

.asset-dashboard-wrapper .tanent-analysis-wrapper .ant-table-thead .ant-table-cell,
.asset-dashboard-wrapper .area-analysis-wrapper .ant-table-thead .ant-table-cell {
  background-color: #00215b;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 14px;
}

.asset-dashboard-wrapper .tanent-analysis-wrapper .ant-table-tbody .ant-table-cell,
.asset-dashboard-wrapper .area-analysis-wrapper .ant-table-tbody .ant-table-cell {
  border: none;
  font-size: 14px;
  font-weight: 400;
}

.asset-dashboard-wrapper .tanent-analysis-wrapper .ant-table-tbody>tr:last-child .ant-table-cell,
.asset-dashboard-wrapper .area-analysis-wrapper .ant-table-tbody>tr:last-child .ant-table-cell {
  background-color: #00215b;
  color: #fff !important;
  font-size: 18px;
}

.asset-dashboard-wrapper .area-analysis-wrapper .ant-table-tbody>tr:nth-child(4) .ant-table-cell,
.asset-dashboard-wrapper .area-analysis-wrapper .ant-table-tbody>tr:nth-child(7) .ant-table-cell {
  background-color: #f2f4f7;
  font-weight: 500;
  font-size: 16px;
}

.asset-dashboard-wrapper .tanent-analysis-wrapper .ant-table-thead>tr:last-child .ant-table-cell,
.asset-dashboard-wrapper .area-analysis-wrapper .ant-table-thead>tr:last-child .ant-table-cell {
  padding: 5px;
  background-color: #676eb4;
  color: #fff !important;
  font-weight: 400;
}

.asset-dashboard-wrapper .ant-card-head-title {
  font-weight: 500 !important;
  font-size: 1.4rem;
}

.office-retail-wrapper .parent {
  position: relative;
}

.office-retail-wrapper .icon {
  position: absolute;
  z-index: 1;
}

.office-retail-wrapper .slider {
  position: absolute;
  z-index: 3;
}

.asset-dashboard-wrapper .sensivity-analysis-table {
  min-height: 270px;
  max-height: 270px;
  overflow: hidden;
  overflow-y: scroll;
}

/* .asset-dashboard-wrapper .financial-wrapper {
    min-height: 434px;
    max-height: 434px;
} */

.asset-dashboard-wrapper .thumbnails img {
  width: 120px;
  height: 70px;
  object-fit: cover;
}

.asset-dashboard-wrapper .thumbnails {
  margin-top: 0;
  background-color: #000000;
  padding: 15px;
}

.asset-dashboard-wrapper .apexcharts-datalabels text {
  color: #000000 !important;
}

.activity-log-wrapper .ant-dropdown-trigger:focus {
  background: rgba(0, 33, 91, 0.05) !important;
}

.cashFlowsComponent .antd-custom-card-table .ant-table table {
  border-spacing: 0;
}

/* .cashFlowsComponent .ant-table-thead>tr:last-child .ant-table-cell {
  padding: 5px;
  background-color: #676eb4;
  color: #fff !important;
  font-weight: 400;
} */

.cashFlowsComponent .antd-custom-card-table .ant-table-tbody .ant-table-cell {
  border-radius: 0;
  border: #f2f3f5 0.5px solid;
}

.rent-roll-custom-table .ant-table-thead .ant-table-cell {
  border-right: 1px solid #fff;
  padding: 0 12px !important;
}

.rent-roll-custom-table .ant-table-tbody .ant-table-cell {
  border-radius: 0;
  border: #f2f3f5 0.5px solid;
}

.cashFlowsComponent .ant-table-thead>tr>th:first-child {
  text-align: left !important;
  padding-left: 13px !important;
}

.cashFlowsComponent .ant-table-tbody>tr>td:first-child {
  text-align: left !important;
}

.cashflow-table .ant-table-tbody tr.ant-table-expanded-row>td {
  background: #fff;
  border-radius: 10px;
}

.cashflow-table .ant-table-tbody>tr .ant-table-cell {
  padding: 11px;
}

/* .cashflow-table .ant-table-tbody>tr:last-child .ant-table-cell,
.cashflow-table .ant-table-tbody>tr:nth-child(17) .ant-table-cell,
.cashflow-table .ant-table-tbody>tr:nth-child(10) .ant-table-cell,
.cashflow-table .ant-table-tbody>tr:nth-child(8) .ant-table-cell,
.cashflow-table .ant-table-tbody>tr:nth-child(6) .ant-table-cell {
    background-color: #676EB426;
} */

/* .cashflow-table .ant-table-tbody>tr:last-child .ant-table-cell:first-child,
.cashflow-table .ant-table-tbody>tr:nth-child(17) .ant-table-cell:first-child,
.cashflow-table .ant-table-tbody>tr:nth-child(10) .ant-table-cell:first-child,
.cashflow-table .ant-table-tbody>tr:nth-child(8) .ant-table-cell:first-child,
.cashflow-table .ant-table-tbody>tr:nth-child(6) .ant-table-cell:first-child {
    font-size: 15px;
    font-weight: 600;
} */

.cashflow-table .ant-table-tbody .highlighted-row {
  /* background-color: #676eb426; */
  background-color: #f2f2f2;
}

.cashflow-table .ant-table-tbody .highlighted-row .ant-table-cell:first-child {
  /* background-color: #e8e9f4; */
  background-color: #f2f2f2;
  /* font-size: 15px;*/
  font-weight: 500;
}

.cashflow-table .ant-table-cell-row-hover {
  background-color: unset;
}

/* Rating */

.rating-wrapper .rectangle {
  /* width: auto; */
  height: 18px;
  background-color: #00215b;
}

.rating-wrapper .bg-very-good {
  background-color: #5cdb5c;
}

.rating-wrapper .bg-good {
  background-color: #99d0ff;
}

.rating-wrapper .bg-average {
  background-color: #d9d9d9;
}

.rating-wrapper .bg-below-average {
  background-color: #fde294;
}

.rating-wrapper .bg-poor {
  background-color: #fe460c;
}

.rating-wrapper .bg-light-grey {
  background-color: #f2f3f5 !important;
}

.rating-wrapper .ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 25px;
  margin: 0;
  padding: 0 5px;
  color: #455560;
  font-size: 14px;
  line-height: 25px;
  background: #fff;
  border: 0 !important;
  border-radius: 0 !important;
  cursor: pointer;
  /* transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s; */
}

.rating-wrapper .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #455560;
  background: #fff;
  border-color: none;
  border: none;
}

.rating-wrapper .ant-radio-button-wrapper:focus-within {
  box-shadow: none !important;
}

.rating-wrapper .tooltip {
  position: relative;
  display: inline-block;
}

.rating-wrapper .tooltip .tooltiptext {
  visibility: visible;
  width: 45px;
  background-color: #00215b;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 70%;
  left: 50%;
  margin-left: -25px;
}

.rating-wrapper .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #00215b transparent transparent transparent;
}

/* .main-project-details .drawer-height {
  height: calc(100vh - 0px);
  width: 100%;
  background-color: #00215b;
  right: 0;
  position: fixed !important;
} */

/* .main-project-details .drawer-height-hidden {
    display: none;
} */

.main-project-details .drawer-close-btn {
  /* position: absolute;
    top: 0;
    left: -1.5rem;
    right: 18.5rem; */
  padding: 5px;
  background: rgba(89, 90, 92, 1);
  border-radius: 5rem;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
}

.custom-drawer-content .drawer-close-btn {
  position: absolute;
  top: 3rem;
  left: -1rem;
  right: 18.5rem;
  padding: 5px;
  background: rgba(89, 90, 92, 1);
  border-radius: 5rem;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
}

.basic-data,
.user-detail-wrapper .ant-table-tbody>tr {
  cursor: pointer;
}

.incomeAndCost .ant-form-item {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 40px;
  text-align: center;
}

.incomeAndCost .editable-cell-value-wrap {
  padding: 10px;
  cursor: pointer;
}

/* .incomeAndCost .editable-row:hover .editable-cell-value-wrap {
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
} */

/* 
.incomecost-wrapper .antd-custom-card-table .ant-table table {
  border-spacing: 0;
}

.incomecost-wrapper .ant-table-thead>tr:last-child .ant-table-cell {
  padding: 5px;
  background-color: #676eb4;
  color: #fff !important;
  font-weight: 400;
}

.incomecost-wrapper .antd-custom-card-table .ant-table-tbody .ant-table-cell {
  border-radius: 0;
  border: #f2f3f5 0.5px solid;
}

.incomecost-wrapper .ant-table-thead>tr>th:first-child {
  text-align: left !important;
  padding-left: 13px !important;
} */

/* 
.incomecost-wrapper .ant-table-tbody>tr>td:first-child {
  text-align: left !important;
} */

.task-assigned-wrapper .ant-card {
  margin-bottom: 8px;
}

.task-assigned-wrapper .pending-status {
  border-top-right-radius: 5px !important;
  padding: 1px 15px;
  background-color: #ffb4581a;
  color: #ffb458;
  position: absolute;
  border-radius: 5px;
  top: -2px;
  right: -2px;
  font-size: 12px;
}

.task-assigned-wrapper .complete-status {
  border-top-right-radius: 5px !important;
  padding: 1px 15px;
  background-color: #5cdb5c1a;
  color: #5cdb5c;
  position: absolute;
  border-radius: 5px;
  top: -2px;
  right: -2px;
  font-size: 12px;
}

/* .user-detail-wrapper .custom-height {
  min-height: 255px;
  max-height: 255px;
  height: 100%;
  overflow-y: auto;
  list-style-type: none;
} */

.task-assigned-wrapper .ant-collapse-header-text {
  color: #00215b;
  font-size: 16px;
  font-weight: 500;
}

.task-assigned-wrapper .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
  padding: 12px 20px 12px 16px;
}

.task-assigned-wrapper .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
  top: 40%;
  font-size: 18px;
}

.task-assigned-wrapper .chat-avatar {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 50%;
}

.task-assigned-wrapper .ant-collapse-header {
  border-top: none !important;
  background-color: #fff;
  border-radius: 5px !important;
}

.task-assigned-wrapper .ant-collapse {
  border-radius: 0.27rem !important;
}

.task-assigned-wrapper .ant-collapse-item:last-child>.ant-collapse-content {
  border-radius: 0 0 0.29rem 0.29rem;
}

.taskchat-wrapper .taskchat-chatcard-inner {
  min-height: 250px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 250px;
}

.task-assigned-wrapper .pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 5px;
  margin: 8px;
}

.task-assigned-wrapper .pagination button {
  width: 35px;
  height: 35px;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.1s ease;
  background-color: #fff;
  border: none;
}

.task-assigned-wrapper .pagination button.active {
  background-color: #00215b;
  color: #fff;
}

.task-assigned-wrapper .pagination button:hover {
  background-color: #fff;
  color: #000000;
}

.task-assigned-wrapper .dot,
.recent-task .dot {
  position: absolute;
  z-index: 1;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  right: 0;
  top: 0;
  border: 3px solid #fff;
}

.recent-task .dot.active {
  border: 3px solid #f2f3f5;
}

.task-assigned-wrapper .task-card {
  border: 2px solid #fff;
  background-color: #fff;
  box-sizing: border-box;
}

.task-assigned-wrapper .task-card:hover {
  border: 2px solid #00215b;
  background-color: #fff;
}

.task-assigned-wrapper .task-card.active {
  border: 2px solid #00215b;
  background-color: #fff;
}

.corporate-dashboard-wrapper .income-cost-filter {
  position: absolute;
  top: 12px;
  right: 12px;
  border-radius: 5px !important;
  z-index: 1;
}

.corporate-dashboard-wrapper .ant-input-suffix {
  margin-left: 4px;
  display: flex;
}

.asset-dashboard-wrapper .upload_btn input[type="file"] {
  display: none;
}

.recent-task .ant-card-body {
  padding: 0 !important;
}

.recent-task .text-grey-shade {
  color: #949494;
}

.recent-task .assign-task p {
  font-size: 12px;
}

/* .recent-task .assign-task {
  max-width: 100%;
  min-width: 100%;
  float: left;
  clear: left;
  overflow-x: scroll;
  overflow-y: hidden;
  border-radius: 5px;
} */

/* .recent-task .task-name,
.recent-task .task-title {
  white-space: nowrap;
} */

.recent-task .assign-task:hover {
  background-color: #f2f3f5;
  border-radius: 5px;
}

.recent-task .assign-task.active {
  background-color: #f2f3f5;
  border-radius: 5px;
}

/* .recent-task .assign-task::-webkit-scrollbar-thumb {
  background: rgba(217, 217, 217, 1) !important;
  border-radius: 10px !important;
}

.recent-task .assign-task::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
} */

.image-preview-modal .image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.edit-profile .upload-icon {
  color: #fff;
  text-align: center;
  display: inline;
}

.edit-profile .bg-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00215b;
  border-radius: 100px;
  width: 35px;
  height: 35px;
}

.edit-profile .upload_btn {
  position: absolute;
  top: 0;
  right: 10px;
}

.rentroll-upload input[type="file"],
.tenants-detail-profile input[type="file"],
.edit-profile input[type="file"] {
  display: none !important;
}

.asset-available-for-rent .carousel-card .ant-card {
  border-radius: 5px !important;
}

.asset-available-for-rent .carousel-card .card-text {
  background-color: #000000a5;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.asset-available-for-rent .react-multiple-carousel__arrow .react-multiple-carousel__arrow--right {
  background-color: #00215b !important;
}

.asset-available-for-rent .react-multiple-carousel__arrow--right {
  position: absolute !important;
  right: 0px !important;
  bottom: 30px;
  background-color: #00215b !important;
  min-height: 15px !important;
  min-width: 25px !important;
  border-radius: 999em 40px 40px 999em !important;
  padding-left: 0 !important;
}

.asset-available-for-rent .react-multiple-carousel__arrow--left::before,
.asset-available-for-rent .react-multiple-carousel__arrow--right::before {
  font-size: 12px !important;
}

.asset-available-for-rent .react-multiple-carousel__arrow--left::before {
  margin-right: 7px;
}

.asset-available-for-rent .react-multiple-carousel__arrow--right::before {
  margin-left: 7px;
}

.asset-available-for-rent .react-multiple-carousel__arrow--left {
  position: absolute !important;
  left: 0px !important;
  bottom: 30px;
  background-color: #00215b !important;
  border-radius: 40px 999em 999em 40px !important;
  min-height: 10px !important;
  min-width: 25px !important;
  padding-right: 0 !important;
}

/* .asset-available-for-rent .ant-card .ant-card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
} */

.admin-dashboard-user .user-card {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  list-style-type: none;
}

.admin-dashboard-wrapper .admin-dashboard-user .custom-scroll {
  height: 333px;
  overflow: hidden;
  overflow-y: scroll;
  list-style-type: none;
}

.admin-dashboard-wrapper .admin-dashboard-user .custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(217, 217, 217, 1);
  border-radius: 10px;
}

.report-user .ant-input-affix-wrapper {
  border-radius: 5px !important;
  /* padding: 5px !important; */
}

.report-user .ant-input-prefix {
  color: #b2b5b8;
  font-size: 16px;
}

.corporate-dashboard-wrapper .select {
  position: absolute;
  top: 50px;
  right: 0;
}

.corporate-dashboard-wrapper .bg-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.2rem;
  /* padding: 15px; */
  background-color: #1585820d;
  border-radius: 50%;
  width: 4.2rem;
}

@media (max-width: 768px) {
  .drawer-close-btn {
    display: none !important;
  }
}

.activity-log-wrapper .row-border {
  border: 1px solid #fff;
}

.activity-log-wrapper .row-active-hover:hover {
  box-sizing: border-box;
  border: 1px solid #00215b;
}

.activity-log-wrapper .row-active-hover.active {
  box-sizing: border-box;
  border: 1px solid #00215b;
}

.activity-log-wrapper .selected-activities {
  height: calc(100vh - 225px);
  overflow: hidden;
  overflow-y: scroll;
}

.recent-task .task-status {
  position: absolute;
  right: 0;
}

.rentRoll .btn-primary {
  color: white;
  background: #00215b;
  padding: 5px 45px;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  text-shadow: none;
}

.erv-table .editable-cell-value-wrap {
  padding: 5px 0px;
  cursor: pointer;
}

.erv-table .ant-form-item {
  width: auto;
}

.rentRoll .drawer-action-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f3f5;
  border-radius: 50%;
  padding: 7px;
  width: 30px;
  height: 30px;
}

/* .rentRoll .ant-table-tbody .ant-table-cell:last-child {
  display: flex;
  justify-
} */

.project-details .antd-custom-card-table .ant-table-tbody .ant-table-cell,
.project .antd-custom-card-table .ant-table-tbody .ant-table-cell,
.user-management .antd-custom-card-table .ant-table-tbody .ant-table-cell {
  /* text-align: center !important; */
  border: none;
  color: rgb(0, 0, 0);
  /* padding: 13px 7px; */
  font-size: 14px;
  /* font-weight: 500; */
  /* border-bottom: 1px solid #d0d5dd; */
}

.ant-pagination-next .anticon-right svg,
.ant-pagination-prev .anticon-left svg {
  display: none !important;
}

.ant-pagination-next,
.ant-pagination-prev {
  display: inline-block;
  min-width: 45px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 15px !important;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-pagination-item-link {
  border-radius: 5px !important;
}

.ant-table-pagination {
  display: flex;
  align-items: center;
}

.ant-pagination-next .anticon-right::after {
  content: "Next";
  font-size: 14px;
  font-weight: 600;
}

.ant-pagination-prev .anticon-left::after {
  content: "Prev";
  font-size: 14px;
  font-weight: 600;
}

.activity-log-wrapper .selected-activity-log {
  height: calc(100vh - 100px);
}

.activity-log-wrapper .all-activity-wrapper {
  height: calc(100vh - 160px);
  overflow: hidden;
  overflow-y: scroll;
  list-style-type: none;
}

/* @media (min-width: 1440px) {
  .ant-modal {
       width:calc(100% - 115px);
  }
} */

@media (max-width: 990px) {
  .search-modals {
    left: 0;
    width: 1200px;
  }
}

@media (min-width: 990px) {
  .search-modals {
    /* left: 115px; */
    /* width: calc(100% - 250px) !important; */
  }

  .search-modals.user {
    /* width: calc(100% - 550px) !important; */
  }
}

/* .ant-modal{
  left: 115px;
  width: calc(100% - 250px) !important;
} */

.search-modals .ant-picker-input {
  padding: 0px 10px !important;
}

.plus-add-icon {
  margin-right: 8px;
  border-radius: 5px !important;
}

.table-footer-btn {
  margin-top: 5px;
}

/* 
ant-table-row ant-table-row-level-0 editable-row */

.custom-datepicker .ant-picker {
  background: transparent;
  border: none;
}

.custom-datepicker .ant-picker-suffix {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: inherit;
  color: #00215b !important;
}

.custom-datepicker .ant-picker-input input {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: inherit;
  color: #00215b !important;
}

.first-calculation-table .ant-table-cell {
  padding: 8px;
}

/* .first-calculation-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0;
  background: transparent;
}
.first-calculation-table .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
  background: transparent;
}
.first-calculation-table .ant-table-tbody > tr > td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
} */

/* .ant-table-row-light {
  background-color: #f2f4f7;
} */

.second-table-mini .ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 0.625rem 0.625rem 0 0;
  border-collapse: separate;
  border-spacing: 0 3px;
  font-size: 12px;
  font-weight: 600;
  color: #000000;
}

.first-calculation-table .ant-table.ant-table-bordered>.ant-table-container {
  border: 1px solid #eeeeee !important;
  border-radius: 5px;
  background: transparent;
}

.first-calculation-table .ant-table.ant-table-bordered>.ant-table-container .ant-table-cell {
  border-right: 1.5px solid #eeeeee !important;
  border-radius: 0;
  background: transparent;
}

.first-calculation-table .ant-table.ant-table-bordered>.ant-table-container .ant-table-cell:last-child {
  border-right: none !important;
  border-radius: 0;
  background: transparent;
}

.first-calculation-table .ant-table.ant-table-bordered>.ant-table-container .ant-table-thead .ant-table-cell {
  border-bottom: 1.5px solid #eeeeee !important;
  border-radius: 0;
  background: transparent;
}

.first-calculation-table .ant-table.ant-table-bordered>.ant-table-container .ant-table-thead .ant-table-cell:first-child {
  border-top-left-radius: 5px !important;
}

.erv-table .editable-cell-value-wrap {
  padding-right: 0 !important;
}

.area-income-counter-field {
  border-radius: 5px;
  padding: 3px;
}

.area-income-counter-field p {
  margin-bottom: 0;
}

.area-income-counter-field .dec-num-btn,
.area-income-counter-field .inc-num-btn {
  border: 0;
  background-color: #f2f3f5;
  height: 25px;
  width: 25px;
  /* padding: 5px 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.area-income-counter-field .dec-num-btn:hover,
.area-income-counter-field .inc-num-btn:hover,
.area-income-counter-field .dec-num-btn:focus,
.area-income-counter-field .inc-num-btn:focus {
  background-color: #00215b !important;
  color: #fff !important;
  border-radius: 5px;
}

.areaAndIncome .ant-input-number {
  border: none;
  height: 28px;
  display: flex;
  align-items: center;
}

.areaAndIncome .ant-input-number-input {
  text-align: center;
}

.slider-wrapper {
  border: 1px solid #b7b7b7 !important;
  border-radius: 5px;
  padding: 3px;
}

/* .areaAndIncome .ant-slider-track {
  background-color: #00215b !important;
}

.areaAndIncome .ant-slider-rail,
.ant-slider-track {
  height: 8px;
}

.areaAndIncome .ant-slider-handle {
  border: 1px solid #b7b7b7;
} */

.areaAndIncome .borderless-field {
  height: 48px;
  display: flex;
  align-items: center;
}