.calculation-setting .ant-tabs-tab-btn {
  margin: 0 0 16px;
}

.GeneralCalculationSetting .ant-form-item-required {
  color: #000 !important;
}
.GeneralCalculationSetting .ant-card-bordered {
  border-radius: 5px;
  background-color: #f2f3f5;
  font-size: xx-small;
  padding: 1px;
}
.GeneralCalculationSetting .ant-card-head {
  border-bottom: 2px solid white;
}
.GeneralCalculationSetting {
  padding: 21px;
}

/* // definit */
.definitioncalculationSetting h5 {
  margin-bottom: 5px;
  margin-top: 4px;
}
.definitioncalculationSetting .btn-styling {
  width: 100%;
}
.definitioncalculationSetting .ant-card-bordered {
  border-radius: 5px;
  background-color: #f2f3f5;
  font-size: xx-small;
  padding: 1px;
}
.definitioncalculationSetting .ant-card-extra {
  padding-top: 0px;
  padding-bottom: "5px" !important;
}

.card-head-heading h6 {
  padding-top: 10px;
}
.definitioncalculationSetting {
  padding: 0px 21px;
}
.definitioncalculationSetting .card-head .ant-card-head-title {
  padding-top: 5px;
}
.definitioncalculationSetting .ant-card-head {
  border-bottom: 2px solid white;
}

.MarketCalculationSetting .ant-input {
  text-align: end;
}
.MarketCalculationSetting .net .ant-input {
  text-align: end;
  background-color:#E3E3E3 !important;
}

  .calculation-setting   .ant-tabs-ink-bar {
  height: 5px;
  background: transparent;
}

 .calculation-setting .ant-tabs-ink-bar::after {
  content: " ";
  position: absolute;
  left: 120%;
  right: 0;
  bottom: 0;
  height: 2px;
  background: #00215B;
  width: 11rem;
  transform: translateX(-86%);
} 


.MarketCalculationSetting .rounding .ant-input {
  text-align: start;
  background-color:#E3E3E3 !important;
}
/* AcquisitionCostsCalculationSetting */

.AcquisitionCostsCalculationSetting .ant-input {
  text-align: end;
}

.Consumer .Consumer-input .ant-col {
  padding-left: 0px;
  padding-right: 0px;
}
/* .ant-pagination {
  justify-content: center;
} */
.Consumer .ant-table-thead > tr > th {
  background: #f2f3f5;
}
.Consumer .ant-table {
  background: #f2f3f5;
}
.calculation-setting #rc-tabs-3-tab-1{
  margin-bottom: 29%;
}

.Consumer-card .image-container {
  background-color: white;
  height: 67px;
  width: 17%;
  border: 1px solid #b7b7b7;
}
.Consumer-card .image-container img {
  padding: 16px;
  /* padding-right: 0px;
  padding-left: 19px;
  width: 89%; */
  width: 100%;
}
.Consumer-card .ant-card-head-title {
  padding-top: 5px;
}
.Consumer .ant-table-tbody .ant-table-cell {
  padding-left: 0px;
  padding-right: 2px;
  height: 31px;
}
.indexValue .ant-input-number-input {
  height: 20px;
  text-align: center;
}

.Consumer.indexValue .ant-table-tbody > tr > td {
  padding: 5px;
}

.Consumer .ant-table-tbody div {
  padding-inline: 15px;
  padding: 2px 7px;
}
.Consumer .ant-table-thead .ant-table-cell {
  text-align: center;
}
.Consumer .ant-table-tbody .ant-input {
  height: 31px;
}
@media (max-width: 993px){
  .definitioncalculationSetting .media-class {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-bottom: 1%;
  }

}
.ant-tabs-nav{
  background-color: white !important;
  border-radius: 5px;
}
.MarketCalculationSetting .horizontal-bar-media{
  display: none;
}
@media (max-width:993px){
  .MarketCalculationSetting .heading-media{
    margin-left: -14px !important;
    margin-right: 0px !important;
  }
  .MarketCalculationSetting .col-media{
    padding-left: 12px !important;
    padding-right: 23px !important;

}
.MarketCalculationSetting .horizontal-bar-media{
  display: block !important;
  border: 1px solid white !important;
  margin-top: 4% !important;

}
.AcquisitionCostsCalculationSetting .less-media{
margin-right: 0px !important;

}
.AcquisitionCostsCalculationSetting .div-media{
  margin-bottom: 3% !important;
}
.AcquisitionCostsCalculationSetting .input-media{
  padding-left: 22px !important;
  width: auto !important;
}
.AcquisitionCostsCalculationSetting .aq-hr{
  display: block !important;
  border: 1px solid white !important;
  margin-top: 4% !important;
  margin-bottom: 4% !important;
}


  }

