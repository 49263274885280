.signupComponent .input-mail-icon {
  top: 288px;
}
.signupComponent .input-key-icon {
  top: 353px;
}
.signupComponent .input-confirm-key-icon {
  top: 416px;
}
.signupComponent .ant-select-arrow {
  right: 8px;
  color: #b2b5b8;
  font-size: 16px;
  font-weight: 700;
}
.signupComponent .antd-custom-select {
  border-bottom: 1px solid #445366;
}
.signupComponent
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 0px !important;
  padding: 0px !important;
  font-weight: bold;
  color: #b2b5b8;
  font-size: 16px;
}
.signupComponent .ant-select-selector {
  box-shadow: none !important;
}
