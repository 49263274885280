/* Forget Password Page*/
.forgetPasswordComponent .input-mail-icon {
  width: 20px;
  height: 18px;
  color: #b2b5b8;
  top: 157px;
}

/* Check Mail*/
.forgetPasswordComponent .free-pik-message {
  position: relative;
  width: 100px;
  height: 100px;
  top: 30px;
  background: rgba(0, 33, 91, 0.06);
  border-radius: 10px;
}
.forgetPasswordComponent .free-pik-message-image {
  padding-top: 20px;
  width: 45px;
  height: 74px;
}
.forgetPasswordComponent .check-mail-text {
  width: 250px;
  color: #9b9ea1;
}
.forgetPasswordComponent .text-color-light-grey {
  color: #9b9ea1;
}
.forgetPasswordComponent .check-mail-try-again-text {
  color: #00215b;
  font-size: 15px;
  font-weight: 500;
}
.forgetPasswordComponent .arrow-icon {
  width: 20px;
  height: 20px;
  color: #595a5c;
  position: relative;
  top: 6px;
  right: 5px;
}
